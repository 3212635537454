import React from 'react';
import { RxCross2 } from "react-icons/rx";

import './SuccessfullMessage.css'

const SuccessfullMessage = ({ popUp, setPopUp }) => {

    const onCloseHandler = () => {
        setPopUp(false);
    };

    return (
        <div
            style={{ cursor: 'pointer' }}
            className={`successWrapper ${popUp === true ? 'd-flex' : 'd-none'} align-items-center justify-content-center`}
            onClick={onCloseHandler}
        >
            <div className='container successCard text-center py-3'>
                <RxCross2
                    style={{ cursor: 'pointer' }}
                    className='fs-3 float-end cursor'
                    onClick={onCloseHandler}
                />
                <br />
                <h4 className='mt-2'>Verify Your Email to Get Started!</h4>
                <p>You're almost there! 🚀 Check your inbox for our verification email and click the link to activate your account.</p>
                <p>📩 Can’t find it? Check your spam or junk folder—it might be hiding!</p>
            </div>
        </div>
    )
};

export default SuccessfullMessage;
