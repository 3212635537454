import axios from "axios";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${process.env.REACT_APP_TOKEN}`
  },
  httpsAgent: {
    rejectUnauthorized: false // Disable SSL certificate validation
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    console.log('Eror is',error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res && res.data && res.data.result) {
      return res.data.result;
    }
    return res;
  },
  async (err) => {
    console.error('Axios Response Error:', err);
    const originalConfig = err.config;
    if(err.response && err.response.data && err.response.data.message) {
      err.message = err.response.data.message;
    }

    return Promise.reject(err);
  }
);

export default instance;
