import { Form, Heading } from "containers/Form/newForm";
import {useState} from "react";
import {Row} from "reactstrap";
import LogoImage from 'template/svg/GoDopartnerLogo.png'
import { Container, Logo, LogoContainer, MainContainer } from "./styled";
import Flags from './flags';
import lockImage from 'template/images/Lock.png'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SuccessfullMessage from "containers/Form/SuccessfullMessage";

function App() {
  const [flagData, setFlagData] = useState('FSA');
  const [popUp, setPopUp] = useState(false);

  // Function to update flag data
  const updateFlagData = (newText) => {
    setFlagData(newText);
  };

  return (
    <MainContainer>
      <Row className="header">
        <LogoContainer>
          <Logo src={LogoImage} />
          <Flags updateFlagData={updateFlagData} style={{display: 'none'}}/>
        </LogoContainer>
      </Row>
      <Row className="top_security">
        <p style={{ marginBottom: '0px', fontSize: '16px' }} className="top_sec_p">
          <img
            src={lockImage}
            style={{ marginTop: '-3px', width: '20px', marginRight: '15px' }}
            alt="Lock Icon"
          />
          Enter your details to create your Partner Account with GODO
        </p>
      </Row>
      {/*<Row className="text-center mt_50">
        <h1 className="secondary">Application Form</h1>
        <p className="primary">Create your live trading account in less than 59 seconds.</p>
  </Row>*/}
      <Container>
        <Form entity={flagData} setPopUp={setPopUp} />
      </Container>
      <SuccessfullMessage popUp={popUp} setPopUp={setPopUp} />
      <div className="footer">
        <div>
          <p className="text-center">For assistance, please contact our Client Service Team at cs@godocm.com</p>
          <p>
            *Risk Warning: Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. The information on this site is not directed at residents of the United States and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation. The domain www.godocm.com is operated by Godo Ltd, which is authorized and regulated by the Financial Service Commission ( FSC ) in Mauritius (License No GB20025812).
          </p>
        </div>
      </div>

      <ToastContainer />
    </MainContainer>
  );
}

export default App;


{/*import React, { useState } from 'react';

const App = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <div>
      <h1>Step {currentStep}</h1>

      {currentStep === 1 && <p>Step 1 Content</p>}
      {currentStep === 2 && <p>Step 2 Content</p>}
      {currentStep === 3 && <p>Step 3 Content</p>}

      <button onClick={previousStep} disabled={currentStep === 1}>
        Previous
      </button>
      <button onClick={nextStep} disabled={currentStep === 3}>
        Next
      </button>
    </div>
  );
};

export default App;*/}