import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Button, CustomForm } from "./style"
import './style.css';
import { toast } from "react-toastify";
import { clientService } from "../../services";
import TokenService from "../../services/token.service";
import { GetCountries, GetState } from "react-country-state-city";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import loader from "../../template/images/loader.gif";
import Select from 'react-select';
import countries from '../../components/json/countriesStates.json';

import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const Form = ({ entity, setPopUp }) => {
    const [refValue, setRefValue] = useState('69');
    const [ptrValue, setPtrValue] = useState('');
    const [phoneDialCode, setPhoneDialCode] = useState('');
    // const [popUp, setPopUp] = useState(false);
    const [userLogin, setUserLogin] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    const [declaration, setDeclaration] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [check, setCheck] = useState(false);
    const [cityid, setCityid] = useState(0);
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [loading, setLoading] = useState(false);

    const [countryList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [rec, setRec] = useState({});

    const [entityValue, setEntityValue] = useState('');
    const [utmMedium, setUtmMedium] = useState('');
    const [singleClick, setSingleClick] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const entityParam = searchParams.get('entity');
        const utm = searchParams.get('utm_medium');

        if (entityParam && utm) {
            setEntityValue(entityParam);
            setUtmMedium(utm);
        } else if (entityParam) {
            setEntityValue(entityParam)
            console.log('entity: ', entityParam);
        } else {
            setUtmMedium(utm);
        }

    }, [entityValue, utmMedium])

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
        // Parse the query parameters from the URL
        const searchParams = new URLSearchParams(window.location.search);
        // Get the value of the 'ref' parameter
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        // Set the value to the state
        if (refParam) {
            console.log('Reference Value is ', refParam);
            setRefValue(refParam);
        }
        if (ptrParam) {
            console.log('Ptr Value is ', ptrParam);
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, []);

    const [formData, setFormData] = useState({
        step1: {
            agentId: refValue, parentId: ptrValue, firstName: '', email: '', phone: '',
            country: '', nationality: '', countries: '', proofOfIdentity: '', proofOfAddress: '', entity: entityValue, utmMedium: utmMedium, clientType: 'Individual',
            companyDescription: false, relationships: false, brokerName: '', promotionPlans: 'Online Advertising', targetedClients: '1-50 Clients', avgDeposit: 'Less than $1,000', legalAffairs: '',
            spotFXmetals: '', futureCFD: '', indexCFD: '', representative: ''
        },
    });


    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, accountType: false, country: false, nationality: false, password: false, confirmPassword: false,
            proofOfIdentity: false, proofOfAddress: false, brokerName: false, legalAffairs: false, spotFXmetals: false, futureCFD: false,
            indexCFD: false, representative: false, relationships: false, companyDescription: false
        }
    });



    const formValueChange = (step, name, value) => {
        if (name === 'email') {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }

        if (name === 'phone') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value.replace(/[^0-9]/g, ''),
                },
            }));
        }

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            [step]: {
                ...prevFormData[step],
                [name]: value,
            },
        }));

        // Add password and confirm password validation logic here if needed
        if (name === 'password' || name === 'confirmPassword') {
            if (formData.step1.password === formData.step1.confirmPassword) {
                setValidationErrors(false);
            }
            validatePassword(formData.step1.password);
            validateConfirmPassword(formData.step1.confirmPassword);
        }
    };



    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,15}$/;
        const isValid = regex.test(password);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };

    const validateConfirmPassword = (confirmPassword) => {
        const { password } = formData.step1;
        const isValid = password === confirmPassword || (password.length === confirmPassword.length + 1);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                confirmPassword: !isValid,
            },
        }));
    };

    // const resetForm = () => {
    //     setFormData({
    //         step1: { agentId: '', firstName: '', lastName: '', email: '', phone: '', city: '', password: '', confirmPassword: '', country: '', nationality: '' },
    //     });
    // }

    const isStep1Valid = () => {
        const { firstName, email, phone, country, nationality, proofOfAddress, proofOfIdentity, countries, spotFXmetals, futureCFD, indexCFD,
            brokerName, legalAffairs, representative, relationships, companyDescription } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        //const islastNameValid = lastName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isCountryValid = country !== '';
        const isNationalityValid = nationality !== '';
        const isProofOfAddress = proofOfAddress !== '';
        const isProofOfIdentity = proofOfIdentity !== '';
        const isCountries = countries !== '';
        const isSpotFXMetals = spotFXmetals !== '';
        const isFutureCFD = futureCFD !== '';
        const isIndexCFD = indexCFD !== '';
        const isBrokerName = brokerName !== '';
        const isLegalAffairs = legalAffairs !== '';
        const isRepresentative = representative !== '';

        if (proofOfIdentity !== (null || "") && proofOfIdentity === proofOfAddress) {
            toast.error('Uploaded files could not same!');
            return false;
        }

        if (relationships === true && brokerName === '') {
            toast.error('Please Fill all Fields!');
        }

        if (companyDescription === true && legalAffairs === '') {
            toast.error('Please Fill all Fields!');
        }

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                country: !isCountryValid,
                nationality: !isNationalityValid,
                proofOfAddress: !isProofOfAddress,
                proofOfIdentity: !isProofOfIdentity,
                countries: !isCountries,
                spotFXmetals: !isSpotFXMetals,
                futureCFD: !isFutureCFD,
                indexCFD: !isIndexCFD,
                brokerName: relationships && !isBrokerName,
                legalAffairs: companyDescription && !isLegalAffairs,
                representative: !isRepresentative
            },
        }));

        // Return true if all fields are valid, false otherwise
        return isfirstNameValid && isEmailValid && isphoneValid &&
            isProofOfIdentity && isProofOfAddress && proofOfAddress !== proofOfIdentity && isCountryValid && isNationalityValid && countries && spotFXmetals &&
            futureCFD && indexCFD && representative;
    };

    const submitForm = (e, action) => {
        e.preventDefault();
        setCheck(true);
        if (action === 'login') {
            window.location.href = "https://partner-login.godocm.com";
        } else if (!singleClick) {
            setSingleClick(true);
            setLoading(true);

            const step1Data = { ...formData.step1, phone: phoneDialCode ? phoneDialCode + formData.step1.phone : formData.step1.phone, agentId: refValue, parentId: ptrValue, entity: entityValue ? entityValue : '', utmMedium: utmMedium ? utmMedium : '', kycStatus: 'new', ibStatus: 'pending' };

            if (!isStep1Valid()) {
                setLoading(false);
                toast.error('Please Fill all Fields!');
                setSingleClick(false);
                console.log('Form data:', step1Data);
                return;
            }

            if (!declaration) {
                setLoading(false);
                setSingleClick(false);
                toast.error('Please accept Declaration!');
                return;
            }

            if (!agreement) {
                setLoading(false);
                setSingleClick(false);
                toast.error('Please accept Agreement!');
                return;
            }

            // Remove unnecessary fields
            delete step1Data.confirmPassword;
            delete step1Data.proofOfAddress;
            delete step1Data.proofOfIdentity;
            delete step1Data.companyDescription;
            delete step1Data.relationships;

            console.log('Form data:', step1Data);

            const token = TokenService.getUser();
            clientService.postPartner(step1Data, token)
                .then((res) => {

                    if (res && res.data && res.data.message) {
                        toast.error(res.data.message); // Display the error message
                        setLoading(false);
                        setSingleClick(false);
                    } else {

                        const formDataValue = new FormData();

                        imageList.forEach((item) => {
                            formDataValue.append(item.key, item.value[0]);
                        });

                        formDataValue.append('customerId', res.id);

                        setLoading(false)
                        toast.success("Successfully Registered!");
                        setPopUp(true);                        

                        return clientService.uploadDocuments(formDataValue)
                            .then((res2) => [res, res2]); // Pass both res and res2 to the next then block

                    }

                })
                // .then(([res, res2]) => {
                //     const authPromise = clientService.authorizationIB(res.id.toString(), formData.step1.password);
                //     return Promise.all([authPromise, res.id]);
                // })
                // .then(([auth, customerId]) => {
                //     const token = auth.token;
                //     const redirectURL = `https://partner-login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                //     window.location.href = redirectURL;
                //     toast.success("Successfully Registered!");
                //     console.log("Added client documents successfully!");
                // })
                .catch((err) => {
                    toast.error("Error adding client or documents!");
                    console.error(err);
                    setSingleClick(false);
                })
                // .finally(() => setLoading(false));
        }
    };

    const updateListFunction = (key, files) => {
        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;
            setImageList(updatedList);
        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };
            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
        }
    };

    // const handlePhoneChange = (status, value, countryData, number, id) => {
    //     setSelectedCountryCode(countryData?.phone_code); // Update the selected country code
    //     // Other logic as needed
    // };

    return (
        <div>
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    <CustomForm style={{ backgroundImage: '../../background.jpg' }}>
                        <div className="justify mt_100">
                            <Row>
                                <Col md={12}>
                                    <div className={`input-group mb-1 d-none`}>
                                        <Label>Partner Account Type</Label>
                                        <div className="form-check form-check-inline ms-5">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="clientType"
                                                id="Individual"
                                                value="Individual"
                                                checked={formData.step1.clientType === 'Individual'}
                                                onChange={(e) => formValueChange('step1', e.target.name, 'Individual')} />
                                            <Label className="form-check-label" htmlFor="Individual">Individual</Label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="clientType"
                                                id="Corporate"
                                                value="Corporate"
                                                checked={formData.step1.clientType === 'Corporate'}
                                                onChange={(e) => formValueChange('step1', e.target.name, 'Corporate')} />
                                            <Label className="form-check-label" htmlFor="Corporate">Corporate</Label>
                                        </div>
                                    </div>
                                </Col>
                                {formData.step1.clientType === 'Corporate' &&
                                    <>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Company's Legal Name</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                                        name="firstName" type="text"
                                                        required="" placeholder="John"
                                                        //invalid={validationErrors.step1.firstName}
                                                        //valid={!validationErrors.step1.firstName}
                                                        value={formData.step1.firstName}
                                                        onChange={(e) => formValueChange('step1', 'firstName', e.target.value)}
                                                    />
                                                </div>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Company's License #</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                                        name="firstName" type="text"
                                                        required="" placeholder="John"
                                                        //invalid={validationErrors.step1.firstName}
                                                        //valid={!validationErrors.step1.firstName}
                                                        value={formData.step1.firstName}
                                                        onChange={(e) => formValueChange('step1', 'firstName', e.target.value)}
                                                    />
                                                </div>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Company's Authorized Representative</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                                        name="firstName" type="text"
                                                        required="" placeholder="John"
                                                        //invalid={validationErrors.step1.firstName}
                                                        //valid={!validationErrors.step1.firstName}
                                                        value={formData.step1.firstName}
                                                        onChange={(e) => formValueChange('step1', 'firstName', e.target.value)}
                                                    />
                                                </div>

                                            </FormGroup>
                                        </Col>
                                    </>
                                }
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Full Name ( as per your passport or national ID )</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (check && !formData.step1.firstName && 'is-invalid')}
                                                name="firstName" type="text"
                                                required="" placeholder="John"
                                                value={formData.step1.firstName}
                                                onChange={(e) => {
                                                    // Remove non-alphanumeric characters except spaces
                                                    let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                    // Split the input into words, capitalize the first letter of each word, and join them back
                                                    let capitalizedInput = cleanedInput.split(' ')
                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ');

                                                    // Update the form value
                                                    formValueChange('step1', 'firstName', capitalizedInput);
                                                }}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Email Address</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (check && !formData.step1.email && 'is-invalid')}
                                                type="email"
                                                name="email"
                                                validation={{ required: true }}
                                                value={formData.step1.email}
                                                invalid={emailError === 1 && formData.step1.email !== '' && 1 || validationErrors.step1.email}
                                                valid={emailError === 0 && formData.step1.email !== '' && 1 || !validationErrors.step1.email}
                                                placeholder="john@gmail.com"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>

                                    </FormGroup>
                                </Col>
                                <Col md={6}>

                                    <FormGroup>
                                        <Label>Country of Residence</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step1.country && 'is-invalid')}
                                            type="select"
                                            name="country"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                if (selectedCountry) {
                                                    setCountryid(selectedCountry.iso2);
                                                    setSelectedCountryName(selectedCountry?.name);
                                                    setSelectedCountryCode(selectedCountry.iso2.toLowerCase());

                                                    if (selectedCountry?.name === 'Morocco') {
                                                        setRefValue('101');
                                                    }

                                                    formValueChange('step1', 'country', selectedCountry.name); // Update form data with the selected country name
                                                    GetState(selectedCountry.id).then((result) => {
                                                        setStateList(result); // Update the state list based on the selected country
                                                        // Reset the selected state and city
                                                        setStateid('');
                                                        setCityList([]);
                                                    });
                                                }
                                            }}
                                            value={countryList.find(country => country.iso2 === countryid)?.name} // Set the value to the selected country name
                                        >
                                            <option value="">-- Select --</option>
                                            {countryList.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>


                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Phone No</Label>
                                        <div className="input-group">
                                            <IntlTelInput
                                                name="phone"
                                                containerClassName="intl-tel-input"
                                                inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                style={{ width: '100%' }}
                                                maxLength={15}
                                                format="true"
                                                defaultCountry={selectedCountryCode || 'ae'}
                                                useMobileFullscreenDropdown={false}
                                                value={formData.step1.phone} // Ensure the value is set correctly
                                                // onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                //     setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                // }}
                                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                    // Remove non-numeric characters
                                                    const numericValue = value.replace(/[^\d]/g, '');
                                                    formValueChange('step1', 'phone', numericValue); // Update form data with the phone number value
                                                    setPhoneDialCode(countryData.dialCode);
                                                    setSelectedCountryName(countryData?.name);
                                                }}
                                            />



                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={formData.step1.clientType === 'Individual' ? 12 : 6}>

                                    <FormGroup>
                                        <Label>Nationality</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step1.nationality && 'is-invalid')}
                                            type="select"
                                            name="nationality"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                if (selectedCountry) {
                                                    formValueChange('step1', 'nationality', selectedCountry.name); // Update form data with the selected country name
                                                }
                                            }}
                                            value={formData.step1.nationality} // Set the value to the selected country name
                                        >
                                            <option value="">-- Select --</option>
                                            {countryList.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={formData.step1.clientType === 'Individual' ? 12 : 6}>

                                            <FormGroup>
                                                <Label>Nationality</Label>
                                                <Input
                                                    className={"mb-3 " + (check && !formData.step1.nationality && 'is-invalid')}
                                                    type="select"
                                                    name="nationality"
                                                    onChange={(e) => {
                                                        const selectedCountryName = e.target.value; // Get the selected country name
                                                        const selectedCountry = countryList.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                        if (selectedCountry) {
                                                            formValueChange('step1', 'nationality', selectedCountry.name); // Update form data with the selected country name
                                                        }
                                                    }}
                                                    //invalid={validationErrors.step1.country}
                                                    //valid={!validationErrors.step1.country}
                                                    value={formData.step1.nationality} // Set the value to the selected country name
                                                >
                                                    <option value="">-- Select --</option>
                                                    {countryList.map((item, index) => (
                                                        <option key={index} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </Input>


                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}

                            {formData.step1.clientType !== 'Corporate' ? <Row>
                                <Col md={6}>
                                    <Label for="proofOfIdentity" className="text-primary fw-bold">Proof of Identity (Passport or National ID)</Label>
                                    <Input
                                        className={"mb-3 " + (check && !formData.step1.proofOfIdentity && "is-invalid")}
                                        type="file"
                                        name="proofOfIdentity"
                                        accept=".jpg, .jpeg, .png, .pdf, .heic"
                                        onChange={(e) => {
                                            const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                            const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                            const fileName = e.target.value;
                                            const file = e.target.files[0];
                                            const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                            if (file) {
                                                if (file.size > maxFileSize) {
                                                    alert('File size exceeds the limit of 5 MB.');
                                                    e.target.value = '';
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                } else if (fileExtension.includes(fileExtensionCheck)) {
                                                    updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                } else {
                                                    alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                    e.target.value = '';
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label for="proofOfAddress" className="text-primary fw-bold">Proof of Address</Label>
                                    <Input
                                        className={"mb-3 " + ((check && !formData.step1.proofOfAddress) && "is-invalid")}
                                        type="file"
                                        name="proofOfAddress"
                                        accept=".jpg, .jpeg, .png, .pdf, .heic"
                                        onChange={(e) => {
                                            const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                            const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                            const fileName = e.target.value;
                                            const file = e.target.files[0];
                                            const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                            if (file) {
                                                if (file.size > maxFileSize) {
                                                    alert('File size exceeds the limit of 5 MB.');
                                                    e.target.value = '';
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                } else if (fileExtension.includes(fileExtensionCheck)) {
                                                    updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                } else {
                                                    alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                    e.target.value = '';
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row> :
                                <Row>
                                    <Col md={6}>
                                        <Label for="proofOfIdentity" className="text-primary fw-bold">Incorpotaion Certificate</Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step1.proofOfIdentity && "is-invalid")}
                                            type="file"
                                            name="proofOfIdentity"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="proofOfAddress" className="text-primary fw-bold">UBO Passport</Label>
                                        <Input
                                            className={"mb-3 " + ((check && !formData.step1.proofOfAddress) && "is-invalid")}
                                            type="file"
                                            name="proofOfAddress"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step1', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="proofOfAddress" className="text-primary fw-bold">UBO Proof of address</Label>
                                        <Input
                                            className={"mb-3 " + ((check && !formData.step1.proofOfAddress) && "is-invalid")}
                                            type="file"
                                            name="proofOfAddress"
                                            onChange={(e) => {
                                                updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                formValueChange('step1', e.target.name, e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="proofOfAddress" className="text-primary fw-bold">Additional</Label>
                                        <Input
                                            className={"mb-3 " + ((check && !formData.step1.proofOfAddress) && "is-invalid")}
                                            type="file"
                                            name="proofOfAddress"
                                            onChange={(e) => {
                                                updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                formValueChange('step1', e.target.name, e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>How do you plan to generate client leads for GoDo?</Label>
                                        <div className="input-group">
                                            <Input
                                                type="select"
                                                name="promotionPlans"
                                                className={((!validationErrors.step1.promotionPlans || formData.step1.promotionPlans) ? 'is-valid' : 'is-invalid')}
                                                value={formData.step1.promotionPlans}
                                                onChange={(e) => {
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                }}
                                            >
                                                <option value="Online Advertising">Online Advertising</option>
                                                <option value="Social Media Marketing">Social Media Marketing</option>
                                                <option value="Referrals">Referrals</option>
                                                <option value="Networking and Partnerships">Networking and Partnerships</option>
                                            </Input>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Which countries will you cover under this Marketing Partner Affiliate relationship?</Label>
                                        <Select
                                            isMulti
                                            name="countries"
                                            options={countries}
                                            className={((!validationErrors.step1.countries || formData.step1.countries) ? 'is-valid' : 'is-invalid') + " form-control basic-multi-select z-5"}
                                            classNamePrefix="select"
                                            onChange={(selectedOptions) => {
                                                // selectedOptions will be an array of selected option objects
                                                const countryValues = selectedOptions.map(option => option.value); // Get the 'value' of each selected option
                                                formValueChange('step1', 'countries', countryValues);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <Label>Do you have any existing marketing or similar relationships with other brokers?</Label>
                                    <div className={`input-group ms-2 mb-1`}>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="relationships"
                                                id="relationshipsYes"
                                                value="Yes"
                                                checked={formData.step1.relationships === true}
                                                onChange={(e) => formValueChange('step1', e.target.name, true)} />
                                            <Label className="form-check-label" htmlFor="relationshipsYes">Yes</Label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="relationships"
                                                id="relationshipsNo"
                                                value="No"
                                                checked={formData.step1.relationships === false}
                                                onChange={(e) => formValueChange('step1', e.target.name, false)} />
                                            <Label className="form-check-label" htmlFor="relationshipsNo">  No</Label>
                                        </div>
                                    </div>
                                </Col>
                                {formData.step1.relationships &&
                                    <>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Name of Brokers</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (formData.step1.relationships && !validationErrors.step1.brokerName ? 'is-valid' : 'is-invalid')}
                                                        type="text"
                                                        name="brokerName"
                                                        validation={{ required: true }}
                                                        value={formData.step1.brokerName}
                                                        placeholder="john"
                                                        onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </>}
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>How many clients do you expect to target through this agreement in the next three months?</Label>
                                        <div className="input-group">
                                            <Input
                                                type="select"
                                                name="targetedClients"
                                                className={((!validationErrors.step1.targetedClients || formData.step1.targetedClients) ? 'is-valid' : 'is-invalid')}
                                                value={formData.step1.targetedClients}
                                                onChange={(e) => {
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                }}
                                            >
                                                <option value="1-50 clients">1-50 clients</option>
                                                <option value="51-100 clients">51-100 clients</option>
                                                <option value="100+ clients">100+ clients</option>
                                            </Input>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>What is the average monthly deposit (in USD) you expect from each targeted client?</Label>
                                        <div className="input-group">
                                            <Input
                                                type="select"
                                                name="avgDeposit"
                                                className={((!validationErrors.step1.avgDeposit || formData.step1.avgDeposit) ? 'is-valid' : 'is-invalid')}
                                                value={formData.step1.avgDeposit}
                                                onChange={(e) => {
                                                    formValueChange('step1', e.target.name, e.target.value);
                                                }}
                                            >
                                                <option value="Less than $1,000">Less than $1,000</option>
                                                <option value="$1,000 - $5000">$1,000 - $5000</option>
                                                <option value="$5001 - $10,000">$5001 - $10,000</option>
                                                <option value="More than $10,000">More than $10,000</option>
                                            </Input>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Have you, or any of the company principals, ever been under investigation or faced litigation from a customer or government authority?</Label>
                                        <div className={`input-group ms-2`}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyDescription"
                                                    id="companyDescriptionYes"
                                                    value="Yes"
                                                    checked={formData.step1.companyDescription === true}
                                                    onChange={(e) => formValueChange('step1', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="companyDescriptionYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyDescription"
                                                    id="companyDescriptionNo"
                                                    value="No"
                                                    checked={formData.step1.companyDescription === false}
                                                    onChange={(e) => formValueChange('step1', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="companyDescriptionNo">  No</Label>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    {formData.step1.companyDescription &&
                                        <>
                                        <textarea
                                            className={((!validationErrors.step1.legalAffairs || formData.step1.legalAffairs) ? 'is-valid' : 'is-invalid') + ' form-control'}
                                            id="w3review"
                                            name="w3review"
                                            placeholder="Provide information..."
                                            rows="4"
                                            cols="100"
                                            maxLength="500"
                                            onChange={(e) => formValueChange('step1', 'legalAffairs', e.target.value)}
                                        >
                                        </textarea>
                                        <p className="fs-6 text-secondary">Max Limit 500 words</p>
                                        </>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Label>What percentage do your clinets trade on: (expected)?</Label>
                                <Col md={12}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Spot FX/Metals %</Label>
                                                <Input className={((!validationErrors.step1.spotFXmetals || formData.step1.spotFXmetals) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="spotFXmetals"
                                                    value={formData.step1.spotFXmetals}
                                                    required
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value.replace(/[^\d]/g, ''))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Future CFDs %</Label>
                                                <Input className={((!validationErrors.step1.futureCFD || formData.step1.futureCFD) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="futureCFD"
                                                    value={formData.step1.futureCFD}
                                                    required
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value.replace(/[^\d]/g, ''))}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Index CFDs %</Label>
                                                <Input className={((!validationErrors.step1.indexCFD || formData.step1.indexCFD) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="indexCFD"
                                                    value={formData.step1.indexCFD}
                                                    required
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value.replace(/[^\d]/g, ''))}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Commodity Futures</Label>
                                                <Input className={"form-control " + 'is-valid' + 'is-invalid'}
                                                    type="text"
                                                    value=''
                                                    placeholder="John"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Off Exchange Index Futures</Label>
                                                <Input className={"form-control " + 'is-valid' + 'is-invalid'}
                                                    type="text"
                                                    value=''
                                                    placeholder="John"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    I, <input type="text" className="form-field border-0 border-bottom" value={formData.step1.firstName} placeholder="" />, representing
                                    <input
                                        type="text"
                                        className={((!validationErrors.step1.representative || formData.step1.representative) ? 'border' : 'border-danger') + " form-field"}
                                        name="representative" onChange={(e) => formValueChange('step1', e.target.name, e.target.value)} placeholder="" /> (company/myself), declares that all marketing activities undertaken in partnership with GODO LTD will comply with relevant laws, regulations, and ethical standards. I/we confirm that there is no conflict of interest, and we will maintain confidentiality and transparency throughout the partnership.
                                </Col>
                                <Col md={12}>
                                    <div className="input-group mt-3">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="acceptedTerms"
                                                className={ declaration ? `text-black` : 'text-danger' }
                                                checked={declaration}
                                                onChange={() => setDeclaration(!declaration)}
                                            />
                                            &nbsp;&nbsp;&nbsp; I/ we acknowledge that being GODO Marketing Partner does not authorized me/us to trade on behalf of any client or receive money directly from them, and I/we understand that violating the conflict of interest policy may result in action being taken against me by GODO.
                                        </label>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="input-group mt-3">
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="declaration"
                                                className={ agreement ? `text-black` : 'text-danger' }
                                                checked={agreement}
                                                onChange={() => setAgreement(!agreement)}
                                            />
                                            &nbsp;&nbsp;&nbsp; I/we have read, understand and agreed to the <a href="https://www.godopartner.com/wp-content/uploads/2025/03/Gen-_-GODO-Partner-_-Terms-of-Business.pdf">GODO Partner's Terms of Business</a>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <div className="justify text_center mt_50">
                                <Button onClick={(e) => submitForm(e, 'login')} className='primary-bg-color' style={{ height: '50px' }}>
                                    {'Log In'}
                                </Button>

                                <Button type="submit" onClick={(e) => submitForm(e, 'submit')} className='primary-bg-color' style={{ height: '50px' }}>
                                    {'Register'}
                                </Button>
                            </div>
                        </div>
                    </CustomForm>
                </>}
        </div>
    )
}
